import { createSlice } from "@reduxjs/toolkit"
import { CONST_STORAGE_KEY } from "../types/type-storage-key"
import { TypeUserStore } from '../types/providers/type-user-store'

export interface TypeAppConfigInitialState {
    storeActiveKey: string
    storeActiveValue: TypeUserStore
}

const initialState: TypeAppConfigInitialState = {
    storeActiveKey: CONST_STORAGE_KEY.User.STOREActive,
    storeActiveValue: JSON.parse(localStorage.getItem(CONST_STORAGE_KEY.User.STOREActive) as string) as TypeUserStore
}

const slice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        setStoreActive: (state, { payload }) => {
            state.storeActiveValue = payload as TypeUserStore
            !payload && localStorage.removeItem(CONST_STORAGE_KEY.User.STOREActive)
            payload && localStorage.setItem(CONST_STORAGE_KEY.User.STOREActive, JSON.stringify(payload as TypeUserStore))
        }
    }
})

export const ReducerAppConfigServiceActions = slice.actions
export const ReducerAppConfigService = slice.reducer
