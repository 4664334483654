import React from "react"
import ReactDOM from 'react-dom'
import Style from "./AppModal.module.scss"
import { TYPE_MODAL } from "./types/type-app-modal"

interface Props {
    modal: TYPE_MODAL
}

function AppModal(props: Props) {
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className={Style['container']}>
            </div>
        </React.Fragment>,
        document.getElementById('root-modal') as HTMLElement
    )
}

export default AppModal