import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import RootGuardCanActivate from "../guards/RootGuardCanActivate"
import RootLayout from "../layouts/root/RootLayout"
import OnBoardPage from "../pages/on-board/OnBoardPage"
import NotFound404Page from "../pages/not-found-404/NotFound404Page"

const RootRouter = () => {
  return <React.Fragment>
    <RootGuardCanActivate>
      <RootLayout>
        <Switch>
          <Redirect exact from={`/`} to={`/on-board`} />
          <Route path={'/on-board'}>
            <OnBoardPage />
          </Route>
          <Route path={'*'}>
            <NotFound404Page />
          </Route>
        </Switch>
      </RootLayout>
    </RootGuardCanActivate>
  </React.Fragment>
}

export default RootRouter
