import React, { useEffect, useState } from "react"
import { Switch, Route, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { TypeReducerState } from "../redux-store"
import ProgressBar from "./atoms/progress-bar/ProgressBar"
import Toast from "./atoms/toast/Toast"
import AuthRouter from "./routers/AuthRouter"
import ClientRouter from "./routers/ClientRouter"
import RootRouter from "./routers/RootRouter"
import AppModal from "./AppModal"
import { helperGetQueryParams } from "./helpers/helper-location"
import { TYPE_MODAL } from "./types/type-app-modal"

const App = () => {
  const location = useLocation()
  const apiLoader = useSelector((state: TypeReducerState) => state.apiLoader)
  const appToast = useSelector((state: TypeReducerState) => state.appToast)
  const [appModal, setAppModal] = useState(TYPE_MODAL.None)

  useEffect(() => {
    const m = helperGetQueryParams(location, 'modal') as TYPE_MODAL
    setAppModal(m ? m : TYPE_MODAL.None)
  }, [location.search])

  return <React.Fragment>
    {apiLoader.counter !== 0 && <ProgressBar type="indeterminate" />}
    {appToast.toastOptions && <Toast toastOptions={appToast.toastOptions} />}
    {appModal !== TYPE_MODAL.None && <AppModal modal={appModal} />}
    <Switch>
      <Route path="/auth">
        <AuthRouter />
      </Route>
      <Route path="/client">
        <ClientRouter />
      </Route>
      <Route path="/">
        <RootRouter />
      </Route>
    </Switch>
  </React.Fragment>
}

export default App
