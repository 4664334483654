import React from 'react'
import Style from './Button.module.scss'

export enum ButtonType {
  Button = "button",
  Reset = "reset",
  Submit = "submit"
}

export enum ButtonColor {
  Danger = "danger",
  Dark = "dark",
  Highlight = "highlight",
  Light = "light",
  Medium = "medium",
  Primary = "primary",
  Secondary = "secondary",
  Normal = "normal",
  Success = "success",
  Tertiary = "tertiary",
  Warning = "warning"
}

export enum ButtonTextColor {
  White = "white"
}

export enum ButtonWidth {
  Content = "content",
  Expand = "expand"
}

interface Props {
  children: string
  type?: ButtonType
  color?: ButtonColor
  TextColor?: ButtonTextColor
  disabled?: boolean
  textColor?: ButtonTextColor
  preIcon?: string,
  preAlt?: string,
  postIcon?: string,
  postAlt?: string,
  width?: ButtonWidth
  handleClick?: () => void
}

const Button = (props: Props) => {
  const handleClick = () => {
    if (props?.handleClick) {
      props.handleClick()
    }
  }

  return <React.Fragment>
    <button type={props?.type ?? ButtonType.Button} className={`${Style["button"]} ${Style[props?.color ?? ButtonColor.Primary]} ${Style[props?.width ?? ButtonWidth.Content]}`} onClick={() => handleClick()}>
      {props?.preIcon && <img src={props.preIcon} alt={props.preAlt} />}
      <span>{props.children}</span>
      {props?.postIcon && <img src={props.postIcon} alt={props.postAlt} />}
    </button>
  </React.Fragment>
}

export default Button
