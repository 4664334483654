import * as H from 'history'
import { TypeLooseObject } from '../types/type-loose-object'
import { TYPE_MODAL } from '../types/type-app-modal'

export const helperGetActiveRoute = (location: H.Location): string => {
    const route = location.pathname.split('/')
    return route?.[1] ? route?.[2] : ''
}

export const helperIsListPageActiveRoute = (location: H.Location): boolean => {
    const route = location.pathname.split('/')
    return 'list' === route?.[route.length - 1]
}

export const helperIsQueryParamsExist = (location: H.Location, keys: string[]): boolean => {
    const urlSearchParams = new URLSearchParams(location.search)
    return !!keys.filter(key => urlSearchParams.has(key)).length
}

export const helperGetQueryParams = (location: H.Location, key: string) => {
    const urlSearchParams = new URLSearchParams(location.search)
    return urlSearchParams.get(key)
}

export const helperQueryStringObject = (location: H.Location) => {
    const urlSearchParams = new URLSearchParams(location.search)
    console.log("urlSearchParams ==>", urlSearchParams.keys())
}

export const helperObjectToQueryString = (obj: TypeLooseObject<string>, prefix: string = '') => {
    let str: string[] = []
    Object.keys(obj).forEach(p => {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p
            let v = obj[p] as TypeLooseObject<string>
            str.push((v !== null && typeof v === "object") ?
                helperObjectToQueryString(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v))
        }
    })
    return str.join("&")
}

export const helperOpenModal = (history: H.History, location: H.Location, modal: TYPE_MODAL, modalProps: TypeLooseObject<string>) => {
    const query = helperObjectToQueryString({ modal, modalProps })
    history.replace(location.pathname + '?' + query)
}

export const helperDismissModal = (history: H.History, location: H.Location, action: string) => {
    const query = helperObjectToQueryString({ modal: TYPE_MODAL.None, action })
    history.replace(location.pathname + '?' + query)
}

export const helperApplySearch = (history: H.History, location: H.Location, search: string) => {
    const query = helperObjectToQueryString({ search })
    if (search)
        history.replace(location.pathname + '?' + query)
    else
        history.replace(location.pathname)
}

export const helperApplyFilter = (history: H.History, location: H.Location, field: string, value: string) => {
    const filter: TypeLooseObject<string> = {}
    filter[field] = value
    const query = helperObjectToQueryString({ filter })
    history.replace(location.pathname + '?' + query)
}