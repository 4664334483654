import React, { useState } from "react"
import Style from "./ProductListPage.module.scss"
import { ProviderUserProductService } from "../../providers/provider-user-product.service"
import ListLayout from "../../layouts/client/list/ListLayout"
import ProductInfoCardComponent from "../../components/product-info-card/ProductInfoCardComponent"
import { TypeUserProduct } from "../../types/providers/type-user-product"


const ProductListPage = () => {
    const [productList, setProductList] = useState<unknown[]>([])

    return <React.Fragment>
        <div className={Style['container']}>
            <ListLayout name="Product" list={productList} setList={setProductList} getList={ProviderUserProductService.getList} getListLength={ProviderUserProductService.getListLength}>
                <React.Fragment>
                    {(productList as TypeUserProduct[]).map(product => <ProductInfoCardComponent key={product.uuid} product={product} />)}
                </React.Fragment>
            </ListLayout>
        </div>
    </React.Fragment >
}

export default ProductListPage