import React, { useState } from 'react'
import Style from './SignInPage.module.scss'
import Button, { ButtonColor, ButtonType, ButtonWidth } from '../../atoms/button/Button'
import BackArrow from '../../../assets/icons/icon_backarrow.svg'
import FieldText from '../../atoms/field-text/FieldText'
import { FieldValues, RegisterOptions, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { CONST_REG_EX } from '../../types/type-reg-ex'
import { ProviderUserAuthService } from '../../providers/provider-user-auth.service'
import { ReducerUserAuthServiceActions } from '../../reducers/reducer-user-auth.service'
import { v4 as uuidV4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { ReducerAPILoaderServiceActions } from '../../reducers/reducer-api-loader.service'
import { AxiosError } from 'axios'
import { TypeAPIErrorResponse } from '../../types/type-api'
import { ReducerAppToastServiceActions } from '../../reducers/reducer-app-toast.service'
import { APP_TOAST_TYPE, TypeToastOptions } from '../../atoms/toast/Toast'
import FieldPassword from '../../atoms/field-password/FieldPassword'
import FieldCheckbox from '../../atoms/field-checkbox/FieldCheckbox'

enum FORM_CONTROL {
    EmailOrPhone = 'EmailOrPhone',
    Password = 'Password',
    RememberMe = 'RememberMe',
}

interface Props {
    role: string
}

const SignInPage = (props: Props) => {
    const form = useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState<boolean>()
    const [formWatchFieldRememberMe] = form.watch([FORM_CONTROL.RememberMe])


    const formControlName = (control: FORM_CONTROL) => {
        let option: RegisterOptions = {}
        switch (control) {
            case FORM_CONTROL.EmailOrPhone:
                option = {
                    required: 'Email Or Phone Number Is Required',
                    pattern: { message: 'Invalid Email Or Phone Number', value: CONST_REG_EX.EmailOrPhone }
                }
                break
            case FORM_CONTROL.Password:
                option = {
                    required: 'Password Is Required'
                }
                break
        }
        return form.register(control, option)
    }

    const getFomrControlError = (name: FORM_CONTROL) => {
        return form.formState.errors?.[name]?.message as string
    }

    const fInValid = (fValues: FieldValues) => {
        console.error("SignInPage InValid From => ", fValues)
    }

    const fValid = (fValues: FieldValues) => {
        if (isLoading) return
        setIsLoading(true)
        dispatch(ReducerAPILoaderServiceActions.pushAPILoading())

        const params = {
            uuid: uuidV4(),
            user_auth_role: props.role,
            password: fValues[FORM_CONTROL.Password],
            email_or_phone: fValues[FORM_CONTROL.EmailOrPhone],
            remember_me: fValues[FORM_CONTROL.RememberMe] ? 1 : 0
        }

        ProviderUserAuthService.signIn(params).then((res) => {
            dispatch(ReducerUserAuthServiceActions.setUserAuthAccountValue(res.data))
            ReducerUserAuthServiceActions.navToUserRoute(history)
        }).catch((err: AxiosError<TypeAPIErrorResponse>) => {
            dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Error, message: err.response?.data.message } as TypeToastOptions))
        }).finally(() => {
            dispatch(ReducerAPILoaderServiceActions.popAPILoading())
            setIsLoading(false)
        })
    }

    const onFormSubmit = (e: any) => {
        e.preventDefault()
        form.handleSubmit(fValid, fInValid)()
    }

    return <React.Fragment>
        <form className={Style['container']} onSubmit={onFormSubmit}>
            <h1 className={Style['title']} >Login</h1>
            <FieldText formContolName={formControlName(FORM_CONTROL.EmailOrPhone)} error={getFomrControlError(FORM_CONTROL.EmailOrPhone)} name={FORM_CONTROL.EmailOrPhone} label="Enter email or phone number" />
            <FieldPassword formControlName={formControlName(FORM_CONTROL.Password)} error={getFomrControlError(FORM_CONTROL.Password)} name={FORM_CONTROL.Password} label='Password' toggle={true} shouldErrorIconVisible={true} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className={Style["link"]} onClick={() => history.push(`/auth/forgot-password?role=${props.role}`)}>Forgot password?</p>
                <FieldCheckbox formContolName={formControlName(FORM_CONTROL.RememberMe)} name={FORM_CONTROL.RememberMe} label='Remember me' formWatchField={formWatchFieldRememberMe} />
            </div>
            <Button type={ButtonType.Submit} width={ButtonWidth.Expand}>Continue</Button>
            <Button preIcon={BackArrow} width={ButtonWidth.Expand} color={ButtonColor.Highlight} handleClick={() => history.goBack()}>
                Want to choose a different role selection?
            </Button>
        </form>
    </React.Fragment>
}

export default SignInPage
