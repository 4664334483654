import React, { useState } from 'react'
import Style from './MenuComponent.module.scss'
import ArrowBackIcon from '../../../assets/icons/icon_arrow_back_ios.svg'
import MenuIcon from '../../../assets/icons/icon_menu.svg'
import DashboardIcon from '../../../assets/icons/icon_dashboard.svg'
import DashboardOutlineIcon from '../../../assets/icons/icon_dashboard_outline.svg'
import StoreIcon from '../../../assets/icons/icon_storefront.svg'
import StoreRoundIcon from '../../../assets/icons/icon_store_round.svg'
import ProductIcon from '../../../assets/icons/icon_bento.svg'
import ProductRoundIcon from '../../../assets/icons/icon_product_round.svg'
import BillIcon from '../../../assets/icons/icon_bill_outline.svg'
import BillRoundIcon from '../../../assets/icons/icon_bill_round.svg'
import { helperGetActiveRoute } from '../../helpers/helper-location'
import { useHistory, useLocation } from 'react-router-dom'

const MenuComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const isRouteActive = (baseRoute: string): string => {
    return helperGetActiveRoute(location) === baseRoute ? Style['active'] : Style['default']
  }

  return <React.Fragment>
    <div className={`${Style['container']} ${isOpen ? Style['sm'] : ''}`}>
      <div className={Style['head']} onClick={() => setIsOpen(v => !v)}>
        <p>My Menu</p>
        {isOpen ? (
          <img className={Style['active']} src={MenuIcon} alt="menu-icon" />
        ) : (
          <img className={Style['default']} src={ArrowBackIcon} alt="arrow-back-icon" />)}
      </div>
      <div className={Style['list']}>
        <div className={Style['item'] + ' ' + isRouteActive('dashboard')} onClick={() => history.replace('/client/dashboard')}>
          <img className={Style['active']} src={DashboardIcon} alt="dashboard.svg" />
          <img className={Style['default']} src={DashboardOutlineIcon} alt="dashboard-outline.svg" />
          <p>Dashboard</p>
        </div>
        <div className={Style['item'] + ' ' + isRouteActive('store')} onClick={() => history.replace('/client/store')}>
          <img className={Style['active']} src={StoreRoundIcon} alt="store_round.svg" />
          <img className={Style['default']} src={StoreIcon} alt="store.svg" />
          <p>Stores</p>
        </div>
        <div className={Style['item'] + ' ' + isRouteActive('product')} onClick={() => history.replace('/client/product')}>
          <img className={Style['active']} src={ProductRoundIcon} alt="product_round.svg" />
          <img className={Style['default']} src={ProductIcon} alt="product_field.svg" />
          <p>Products</p>
        </div>
        <div className={Style['item'] + ' ' + isRouteActive('bill')} onClick={() => history.replace('/client/bill')}>
          <img className={Style['active']} src={BillRoundIcon} alt="bill-round.svg" />
          <img className={Style['default']} src={BillIcon} alt="bill.svg" />
          <p>Bill</p>
        </div>
      </div>
    </div>
  </React.Fragment >
}

export default MenuComponent
