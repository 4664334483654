import React, { useEffect, useRef, useState } from 'react'
import Style from './BillAddPreviewPage.module.scss'
import BillSlipComponent from '../../components/bill-slip/BillSlipComponent'
import { useReactToPrint } from 'react-to-print'
import Button, { ButtonColor, ButtonType } from '../../atoms/button/Button'
import { useForm, RegisterOptions, FieldValues } from 'react-hook-form'
import FieldTextarea from '../../atoms/field-textarea/FieldTextarea'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ReducerAPILoaderServiceActions } from '../../reducers/reducer-api-loader.service'
import { ProviderUserBillService } from '../../providers/provider-user-bill.service'
import EditBillIcon from '../../../assets/icons/icon_edit_bill.svg'
import NewBillIcon from '../../../assets/icons/icon_new_bill.svg'
import PrintBillIcon from '../../../assets/icons/icon_print_bill.svg'
import { APP_TOAST_TYPE, TypeToastOptions } from '../../atoms/toast/Toast'
import { ReducerAppToastServiceActions } from '../../reducers/reducer-app-toast.service'
import { AxiosError } from 'axios'
import { TypeAPIErrorResponse } from '../../types/type-api'

enum FORM_CONTROL {
    Discount = 'discount',
    Note = 'note'
}

const BillPreviewPage = () => {
    const form = useForm()
    const history = useHistory()
    const dispatch = useDispatch()
    const componentRef = useRef(null)
    const queryParams = new URLSearchParams(useLocation().search)
    const [bill, setBill] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isPritable, setIsPritable] = useState<boolean>()

    useEffect(() => {
        if (queryParams.get('bill')) {
            const localBill = JSON.parse(localStorage.getItem('bill-' + queryParams.get('bill')) as string)
            if (!localBill) {
                dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Info, message: 'Link Expire Add New Bill' } as TypeToastOptions))
                history.replace("/client/bill/add")
            } else {
                localBill['total'] = localBill['subTotal'] = localBill['products'].reduce((sum: number, product: any) => sum + product.price * product.noOfItems, 0)
                setBill(localBill)
            }
            console.log(localBill)
        } else {
            history.replace("/client/bill/add")
        }
    }, [])

    useEffect(() => {
        setBill((b: any) => {
            b['discountPercentage'] = form.getValues(FORM_CONTROL.Discount) || 0
            b['discountAmount'] = b['subTotal'] * b['discountPercentage'] / 100
            b['total'] = Number(b['subTotal'] - b['discountAmount']).toFixed(2)
            return { ...b }
        })
    }, [form.watch(FORM_CONTROL.Discount)])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    useEffect(() => {
        if (isPritable) {
            handlePrint()
        }
    }, [isPritable])

    const formControlName = (control: FORM_CONTROL) => {
        let option: RegisterOptions = {}
        switch (control) {
            case FORM_CONTROL.Discount:
                option = {
                    onChange: (el) => {
                        if (Number(el.target.value) < 0) {
                            form.setValue(FORM_CONTROL.Discount, 0)
                        } else if (Number(el.target.value) > 15) {
                            form.setValue(FORM_CONTROL.Discount, 15)
                        }
                    }
                }
        }
        return form.register(control, option)
    }

    const getFormControlError = (name: FORM_CONTROL) => {
        return form.formState.errors?.[name]?.message as string
    }

    const fInValid = (fErrors: any) => {
        console.log('Error =>', fErrors)
    }

    const fValid = (fValues: FieldValues) => {
        if (isLoading) return
        setIsLoading(true)
        dispatch(ReducerAPILoaderServiceActions.pushAPILoading())

        if (!bill)
            return

        const payload = {
            uuid: bill['uuid'],
            products: JSON.stringify(bill['products']),
            sub_total: Number(bill['subTotal']),
            discount_amount: Number(bill['discountAmount']),
            discount_percentage: Number(bill['discountPercentage']),
            total: Number(bill['total']),
            note: fValues[FORM_CONTROL.Note]
        }

        ProviderUserBillService.add(payload).then((res) => {
            const gstTimezone = ((res.data.data as any)['0']['timestamp'] as string).replace(" ", "T") + '.000Z'
            var istTimezone = new Date(gstTimezone).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })
            const date = istTimezone.split(', ')[0]
            const time = istTimezone.split(', ')[1]

            setBill((preBill: any) => {
                preBill['id'] = (res.data.data as any)['0']['id'] as string
                preBill['date'] = date
                preBill['time'] = time
                return { ...preBill }
            })
            localStorage.removeItem('bill-' + bill['uuid'])
            setIsPritable(true)
        }).catch((err: AxiosError<TypeAPIErrorResponse>) => {
            dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Error, message: err.response?.data.message } as TypeToastOptions))
        }).finally(() => {
            dispatch(ReducerAPILoaderServiceActions.popAPILoading())
            setIsLoading(false)
        })
    }

    const onFormSubmit = (e: any) => {
        e.preventDefault()
        form.handleSubmit(fValid, fInValid)()
    }

    const newBill = () => {
        localStorage.removeItem('bill-' + bill['uuid'])
        history.replace("/client/bill/add")
    }

    return <React.Fragment>
        <div className={Style['container']}>
            <div className={Style['bill']}>
                {bill && <BillSlipComponent bill={bill} ref={componentRef} />}
            </div>
            <form className={Style['content']} onSubmit={onFormSubmit}>
                <div className={Style['btn-group1']}>
                    <h1>Bill Summary</h1>
                    <Button preIcon={EditBillIcon} color={ButtonColor.Highlight} handleClick={() => history.replace("/client/bill/add?bill=" + queryParams.get('bill'))}>Edit Bill</Button>
                </div>
                <p>Optional order summary</p>
                <div className={Style['form']}>
                    <FieldTextarea formContolName={formControlName(FORM_CONTROL.Note)} error={getFormControlError(FORM_CONTROL.Note)} name={FORM_CONTROL.Note} label="Note" />
                </div>
                <div className={Style['btn-group2']}>
                    <Button color={ButtonColor.Danger} handleClick={newBill}>Cancel</Button>
                    <Button preIcon={NewBillIcon} handleClick={newBill}>New Bill</Button>
                    <Button preIcon={PrintBillIcon} type={ButtonType.Submit}>Print Bill</Button>
                </div>
            </form>
        </div>
    </React.Fragment>
}

export default BillPreviewPage
