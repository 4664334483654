import { AxiosError } from 'axios'
import { ProviderUserAuthService } from "../providers/provider-user-auth.service"
import { ReducerUserAuthServiceActions } from "../reducers/reducer-user-auth.service"

export const ErrorInterceptor = (err: AxiosError) => {
    if (err.response?.status === 401) {
        ProviderUserAuthService.signOut()
        ReducerUserAuthServiceActions.setUserAuthAccountValueWithoutRedux(null)
        ReducerUserAuthServiceActions.navToUserRoute()
    } else {
        if (!err.response) {
            return Promise.reject({
                ...err,
                response: {
                    data: {
                        type: err.code,
                        message: err.message,
                        data: -1
                    }
                }
            })
        }
        return Promise.reject(err)
    }
}