import { createSlice } from "@reduxjs/toolkit"
import { TypeToastOptions } from "../atoms/toast/Toast"

export interface TypeAppToastInitialState {
    toastOptions?: TypeToastOptions
}

const initialState: TypeAppToastInitialState = {
    toastOptions: undefined
}

const slice = createSlice({
    name: 'appToast',
    initialState,
    reducers: {
        showToast: (state, { payload }) => {
            state.toastOptions = payload as TypeToastOptions
        },
        hideToast: (state) => {
            state.toastOptions = undefined
        }
    }
})

export const ReducerAppToastServiceActions = slice.actions
export const ReducerAppToastService = slice.reducer
