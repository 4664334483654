import { configureStore } from '@reduxjs/toolkit'
import { ReducerAPILoaderService, TypeAPILoaderInitialState } from './app/reducers/reducer-api-loader.service'
import { ReducerUserAuthService, TypeUserAuthInitialState } from './app/reducers/reducer-user-auth.service'
import { ReducerAppToastService, TypeAppToastInitialState } from './app/reducers/reducer-app-toast.service'
import { ReducerAppConfigService, TypeAppConfigInitialState } from './app/reducers/reducer-app-config.service'

export const ReduxStore = configureStore({
  reducer: {
    apiLoader: ReducerAPILoaderService,
    appConfig: ReducerAppConfigService,
    appToast: ReducerAppToastService,
    userAuth: ReducerUserAuthService
  }
})

export interface TypeReducerState {
  apiLoader: TypeAPILoaderInitialState,
  appConfig: TypeAppConfigInitialState,
  appToast: TypeAppToastInitialState,
  userAuth: TypeUserAuthInitialState
}