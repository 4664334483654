import React from 'react'
import Style from './FieldTextarea.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form'

interface Props {
    formContolName: UseFormRegisterReturn<string>
    name: string
    label: string
    error?: string
}

const FieldTextarea = (props: Props) => {
    return <React.Fragment>
        <div className={Style['container']}>
            <textarea {...props.formContolName} id={props.name} className={props?.error ? Style['error'] : ''} name={props.name} placeholder=''></textarea>
            <label className={props?.error ? Style['error'] : ''} htmlFor={props.name}>{props.label}</label>
            {props?.error && <p className={Style['error']}>{props.error}</p>}
        </div>
    </React.Fragment>
}

export default FieldTextarea
