import React from 'react'
import Style from './HeaderStripComponent.module.scss'
import ImgLogoMomoPunch from '../../../assets/images/img_logo-momo_punch.svg'
import { useSelector } from 'react-redux'
import { TypeReducerState } from '../../../redux-store'

const HeaderStripComponent = () => {
  const storeActiveValue = useSelector((state: TypeReducerState) => state.appConfig.storeActiveValue)

  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['left']}>
        <div className={Style['active-store']}>
          <img className={Style['logo']} src={ImgLogoMomoPunch} alt="ImgLogoMomoPunch" />
          <div className={Style['text']}>
            <h2 className={Style['title']}>{storeActiveValue?.name}</h2>
            <p className={Style['address']}>{storeActiveValue?.address}</p>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default HeaderStripComponent