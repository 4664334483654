import React from 'react'
import Style from './DataLoadErrorComponent.module.scss'
import ImgDataloadError from '../../../assets/images/img_dataload_error.svg'
import IconReload from '../../../assets/icons/icon_reload.svg'
import Button, { ButtonColor } from '../../atoms/button/Button'

interface Props {
  msg: string,
  handleRefresh: () => void
}

const DataLoadError = (props: Props) => {
  return <React.Fragment>
    <div className={Style['container']}>
      <img src={ImgDataloadError} alt='ImgDataloadError' />
      <p>{props.msg}</p>
      <Button preIcon={IconReload} preAlt='IconReload' color={ButtonColor.Highlight} handleClick={props.handleRefresh}>Reload</Button>
    </div>
  </React.Fragment>
}

export default DataLoadError
