import React from 'react'
import Style from './FieldCheckbox.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form'
import IconCheckBox from '../../../assets/icons/icon_check_box.svg'
import IconCheckBoxOutlineBlank from '../../../assets/icons/icon_check_box_outline_blank.svg'

interface Props {
    formWatchField: boolean
    formContolName: UseFormRegisterReturn<string>
    name: string
    label: string
}

const FieldCheckbox = (props: Props) => {
    return <React.Fragment>
        <div className={Style['container']}>
            {props.formWatchField && <img src={IconCheckBox} alt="IconCheckBox" className={Style['img-check-box']} />}
            {!props.formWatchField && <img src={IconCheckBoxOutlineBlank} alt="IconCheckBoxOutlineBlank" className={Style['img-check-box']} />}
            <input type="checkbox" id={props.name} className={Style['checkbox']} {...props.formContolName} name={props.name} />
            <label className={Style['label']} htmlFor={props.name}>{props.label}</label>
        </div>
    </React.Fragment>
}

export default FieldCheckbox
