import React from 'react'
import AuthGuardCanActivate from './AuthGuardCanActivate'

interface Props {
  children: JSX.Element
}

const RootGuardCanActivate = (props: Props) => {
  return <React.Fragment>
    <AuthGuardCanActivate >
      {props.children}
    </AuthGuardCanActivate>
  </React.Fragment>
}

export default RootGuardCanActivate