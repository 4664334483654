import React from 'react'
import Style from './NoDataFoundComponent.module.scss'
import ImgComponentNoDataFound from '../../../assets/images/img_component-no_data_found.svg'
import IconAdd$ffffff from '../../../assets/icons/icon_add-ffffff.svg'
import Button from '../../atoms/button/Button'

interface Props {
  msg: string,
  handleAdd: () => void
}

const NoDataFoundComponent = (props: Props) => {
  return <React.Fragment>
    <div className={Style['container']}>
      <img src={ImgComponentNoDataFound} alt='ImgComponentNoDataFound' />
      <p>{props.msg}</p>
      <Button preIcon={IconAdd$ffffff} preAlt='IconAdd$ffffff' handleClick={props.handleAdd}>Add</Button>
    </div>
  </React.Fragment>
}

export default NoDataFoundComponent
