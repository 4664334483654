import React from "react"
import Style from "./Spinner.module.scss"

const Spinner = () => {
    return <React.Fragment>
        <div role="spinner" className={`${Style['spinner-wrapper']} ${Style['p-progress-bar-indeterminate']}`} >
            <svg className={Style['spinner']} viewBox="0 0 50 50">
                <circle className={Style['path']} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </div>
    </React.Fragment>
}

export default Spinner
