import React, { useState } from "react"
import Style from "./BillListPage.module.scss"
import { ProviderUserBillService } from "../../providers/provider-user-bill.service"
import ListLayout from "../../layouts/client/list/ListLayout"
import BillInfoCardComponent from "../../components/bill-info-card/BillInfoCardComponent"
import { TypeUserBill } from "../../types/providers/type-user-bill"

const BillListPage = () => {
    const [billList, setBillList] = useState<unknown[]>([])

    return <React.Fragment>
        <div className={Style['container']}>
            <ListLayout name="Bill" list={billList} setList={setBillList} getList={ProviderUserBillService.getList} getListLength={ProviderUserBillService.getListLength}>
                <React.Fragment>
                    {(billList as TypeUserBill[]).map(bill => <BillInfoCardComponent key={bill.uuid} bill={bill} />)}
                </React.Fragment>
            </ListLayout>
        </div>
    </React.Fragment >
}

export default BillListPage