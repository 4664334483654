import React, { useState, ChangeEvent, KeyboardEvent, useRef } from 'react'
import Style from './FieldOtp.module.scss'

const FieldOtp = () => {
    const [values, setValues] = useState<string[]>(['', '', '', '', ''])
    const inputsRef = useRef<Array<HTMLInputElement | null>>([])

    const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        if (value.length <= 1 && !isNaN(parseInt(value))) {
            const newValues = [...values]
            newValues[index] = value
            setValues(newValues)

            if (index < 4 && value !== '') {
                if (inputsRef.current[index + 1]) {
                    inputsRef.current[index + 1]?.focus()
                }
            }
        }
    }

    const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace') {
            event.preventDefault()
            if (index >= 0) {
                const newValues = [...values]
                newValues[index] = ''
                setValues(newValues)
                if (inputsRef.current[index - 1]) {
                    inputsRef.current[index - 1]?.focus()
                }
            }
        }
    }

    return <React.Fragment>
        <div className={Style['container']}>
            {values.map((value, index) => (
                <input
                    key={index}
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength={1}
                    ref={(input) => (inputsRef.current[index] = input)}
                />
            ))}
        </div>
    </React.Fragment>
}

export default FieldOtp
