import React from "react"
import Style from "./ProductAddCardComponent.module.scss"
import IconAdd from "../../../assets/icons/icon_add-333333.svg"
import ImgThumbnail from "../../../assets/images/img_thumbnail.svg"
import { TypeUserProduct } from "../../types/providers/type-user-product"

interface Props {
  product: TypeUserProduct
  isVisibleProduct: boolean
  handleProduct: (product: TypeUserProduct, num: number) => void
}

const ProductAddCardComponent = (props: Props) => {
  return <React.Fragment>
    <div className={Style["container"]} style={{ display: props.isVisibleProduct ? 'flex' : 'none' }}>
      <div className={Style["product-img"]}>
        <img src={ImgThumbnail} alt="ImgThumbnail" />
      </div>
      <div className={Style["containt"]}>
        <div className={Style["text-part"]}>
          <p className={Style["heading"]}>{props.product.name} - {props.product.net_quantity}{props.product.unit}</p>
          <p className={Style["sub-heading"]}>MRP - ₹{props.product.price}</p>
        </div>
      </div>
      <div className={Style["icon"]}>
        <button className="IconAdd" onClick={() => props.handleProduct(props.product, 1)}>
          <img src={IconAdd} alt="Add icon" />
        </button>
      </div>
    </div>
  </React.Fragment>
}
export default ProductAddCardComponent
