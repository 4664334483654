import React, { useState } from "react"
import Style from "./StoreListPage.module.scss"
import { TypeUserStore } from "../../types/providers/type-user-store"
import { ProviderUserStoreService } from "../../providers/provider-user-store.service"
import ListLayout from "../../layouts/client/list/ListLayout"
import StoreInfoCardComponent from "../../components/store-info-card/StoreInfoCardComponent"

const StoreListPage = () => {
    const [storeList, setStoreList] = useState<unknown[]>([])

    return <React.Fragment>
        <div className={Style['container']}>
            <ListLayout name="Store" list={storeList} setList={setStoreList} getList={ProviderUserStoreService.getList} getListLength={ProviderUserStoreService.getListLength}>
                <React.Fragment>
                    {(storeList as TypeUserStore[]).map(store => <StoreInfoCardComponent key={store.uuid} store={store} />)}
                </React.Fragment>
            </ListLayout>
        </div>
    </React.Fragment >
}

export default StoreListPage