import { AxiosResponse } from 'axios'
import { helperHttpAxios } from '../helpers/helper-http-axios'
import { TypeUserProduct } from '../types/providers/type-user-product'
import { TypeAPIAddResponse, TypeAPIErrorResponse, TypeAPIGetListLengthResponse, TypeAPIGetListResponse } from '../types/type-api'

const API_URL = '/api/user/product/'

const add = (params: object = {}): Promise<AxiosResponse<TypeAPIAddResponse, TypeAPIErrorResponse>> => {
    return helperHttpAxios.post(API_URL + 'add', params)
}

const getList = (params: object = {}): Promise<AxiosResponse<TypeAPIGetListResponse<TypeUserProduct>, TypeAPIErrorResponse>> => {
    return helperHttpAxios.get(API_URL + 'get-list', { params })
}

const getListLength = (params: object = {}): Promise<AxiosResponse<TypeAPIGetListLengthResponse, TypeAPIErrorResponse>> => {
    return helperHttpAxios.get(API_URL + 'get-list-length', { params })
}

export const ProviderUserProductService = {
    add,
    getList,
    getListLength
}