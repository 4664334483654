import React from 'react'
import Style from './EmployeeInfoCardComponent.module.scss'
import IconDot from '../../../assets/icons/icon_more_vert.svg'
import { TypeUserEmployee } from '../../types/providers/type-user-employee'

interface Props {
  employee: TypeUserEmployee
}

const EmployeeInfoCardComponent = (props: Props) => {
  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['short-icon']}>
        <p>G</p>
      </div>
      <div className={Style['left-contant']}>
        <div className={Style['text-part']}>
          <p className={Style['heading']}> Anuradha rai </p>
          <p className={Style['sub-heading']}>123 secret street, big building, street name, city, state</p>
        </div>
      </div>
      <div className={Style['right-contant']}>
        <span>verified</span>
        <img src={IconDot} alt="Three dot icons" />
      </div>
    </div>
  </React.Fragment>
}

export default EmployeeInfoCardComponent


