import React, { useState } from 'react'
import Style from './HeaderComponent.module.scss'
import ImgLogoBmClient from '../../../assets/images/img_logo-bm_client.svg'
import IconApps from '../../../assets/icons/icon_apps.svg'
import IconAccountCircle from '../../../assets/icons/icon_account_circle.svg'
import HeaderUserActionsPopup from '../../popups/header-user-actions/HeaderUserActionsPopup'
import AppPopup from '../../AppPopup'

const HeaderComponent = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['left']}>
        <img src={IconApps} alt="IconApps" />
        <img src={ImgLogoBmClient} alt="ImgLogoBmClient" />
      </div>
      <div className={Style['right']}>
        <img src={IconAccountCircle} alt="IconAccountCircle" onClick={() => setIsPopupOpen(v => !v)} />
        {isPopupOpen && <AppPopup top={'66px'} right={'28px'}>
          <HeaderUserActionsPopup />
        </AppPopup>
        }
      </div>
    </div>
  </React.Fragment>
}

export default HeaderComponent
