import React, { useState } from 'react'
import Style from './ForgotPasswordPage.module.scss'
import Button, { ButtonColor, ButtonType, ButtonWidth } from '../../atoms/button/Button'
import FieldText from '../../atoms/field-text/FieldText'
import { FieldValues, RegisterOptions, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { ProviderUserAuthService } from '../../providers/provider-user-auth.service'
import { ReducerAPILoaderServiceActions } from '../../reducers/reducer-api-loader.service'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { TypeAPIErrorResponse } from '../../types/type-api'
import { ReducerAppToastServiceActions } from '../../reducers/reducer-app-toast.service'
import { APP_TOAST_TYPE, TypeToastOptions } from '../../atoms/toast/Toast'
import { CONST_REG_EX } from '../../types/type-reg-ex'
import { v4 as uuidV4 } from 'uuid'

enum FORM_CONTROL {
    EmailOrPhone = 'EmailOrPhone'
}
interface Props {
    role: string
}

const ForgotPasswordPage = (props: Props) => {
    const form = useForm()
    const history = useHistory()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const formControlName = (control: FORM_CONTROL) => {
        let option: RegisterOptions = {}
        switch (control) {
            case FORM_CONTROL.EmailOrPhone:
                option = {
                    required: '*Email or Phone Is Required',
                    pattern: {
                        value: CONST_REG_EX.EmailOrPhone,
                        message: 'Invalid Email or Phone Number'
                    }
                }
                break
        }
        return form.register(control, option)
    }

    const getFormControlError = (name: FORM_CONTROL) => {
        return form.formState.errors?.[name]?.message as string
    }

    const fInValid = (fErrors: any) => {
        console.log('Error =>', fErrors)
    }

    const fValid = (fValues: FieldValues) => {
        if (isLoading) return
        setIsLoading(true)
        dispatch(ReducerAPILoaderServiceActions.pushAPILoading())

        const params = {
            uuid: uuidV4(),
            user_auth_role: props.role,
            email_or_phone: fValues[FORM_CONTROL.EmailOrPhone],
        }

        ProviderUserAuthService.forgotPassword(params)
            .then((res) => {
                dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Success, message: res?.data.message } as TypeToastOptions))
                history.push(`/auth/reset-password?role=${props.role}`)
            }).catch((err: AxiosError<TypeAPIErrorResponse>) => {
                dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Error, message: err.response?.data.message } as TypeToastOptions))
            }).finally(() => {
                dispatch(ReducerAPILoaderServiceActions.popAPILoading())
                setIsLoading(false)
            })
    }

    const onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        form.handleSubmit(fValid, fInValid)()
    }

    return <React.Fragment>
        <form className={Style['container']} onSubmit={onFormSubmit}>
            <h1 className={Style['title']}>Forgot Password</h1>
            <FieldText
                formContolName={formControlName(FORM_CONTROL.EmailOrPhone)}
                error={getFormControlError(FORM_CONTROL.EmailOrPhone)}
                name={FORM_CONTROL.EmailOrPhone}
                label="Enter email or phone number"
            />
            <Button type={ButtonType.Submit} width={ButtonWidth.Expand}>Continue</Button>
            <Button color={ButtonColor.Danger} width={ButtonWidth.Expand} handleClick={() => history.goBack()}>
                Cancel
            </Button>
        </form>
    </React.Fragment>
}

export default ForgotPasswordPage
