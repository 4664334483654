import axios, { AxiosResponse } from "axios"
import { JwtInterceptor } from "../interceptors/jwt.interceptor"
import { ErrorInterceptor } from "../interceptors/error.interceptor"

export const helperHttpAxios = axios.create({ baseURL: process.env.REACT_APP_ENVIRONMENT_BASE_URL })

helperHttpAxios.interceptors.request.use(JwtInterceptor, (err) => {
    return Promise.reject(err)
})

helperHttpAxios.interceptors.response.use((res: AxiosResponse) => {
    return res
}, ErrorInterceptor)