import React from 'react'
import Style from './StoreInfoCardComponent.module.scss'
import IconDot from '../../../assets/icons/icon_more_vert.svg'
import { TypeUserStore } from '../../types/providers/type-user-store'
import { useDispatch, useSelector } from 'react-redux'
import { TypeReducerState } from '../../../redux-store'
import { ReducerAppConfigServiceActions } from '../../reducers/reducer-app-config.service'

interface Props {
    store: TypeUserStore
}

const StoreInfoCardComponent = (props: Props) => {
    const dispatch = useDispatch()
    const isActiveStore = useSelector((state: TypeReducerState) => state.appConfig.storeActiveValue?.uuid === props.store.uuid)

    const getStoreLogoText = (name: string): string => {
        const splitName = name.split(' ')
        const firstChar = splitName[0]?.toUpperCase().trim().at(0) as string
        const secondChar = splitName[1]?.toUpperCase().trim().at(0) as string
        return secondChar ? firstChar + secondChar : firstChar
    }

    return <React.Fragment>
        <div className={Style['container']} onClick={() => dispatch(ReducerAppConfigServiceActions.setStoreActive(props.store))}>
            <div className={Style['short-icon']}>
                <p className={Style['store-logo']}>{getStoreLogoText(props.store.name)}</p>
                {isActiveStore && <div className={Style['online-status']}></div>}
            </div>
            <div className={Style['content']}>
                <div className={Style['text-part']}>
                    <p className={Style['heading']}>{props.store.name}</p>
                    <p className={Style['sub-heading']}>{props.store.address}</p>
                </div>
                <div className={Style['end-part']}>
                    <span>Open</span>
                    <img src={IconDot} alt="Three dot icons" />
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default StoreInfoCardComponent
