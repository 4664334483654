import React, { useState } from 'react'
import Style from './FieldPassword.module.scss'
import IconOn from '../../../assets/icons/icon_visibility.svg'
import IconOff from '../../../assets/icons/icon_visibility_off.svg'
import { UseFormRegisterReturn } from 'react-hook-form'
import IconError from '../../../assets/icons/icon_error_circle.svg'

interface Props {
  formControlName: UseFormRegisterReturn<string>
  name: string
  label: string
  error?: string
  toggle?: boolean
  shouldErrorIconVisible?: boolean
}

const FieldPassword = (props: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return <React.Fragment>
    <div className={Style['container']}>
      <input {...props.formControlName} id={props.name} className={props?.error ? Style['error'] : ''} type={isPasswordVisible ? "text" : "password"} name={props.name} placeholder='' />
      <label className={props?.error ? Style['error'] : ''} htmlFor={props.name}>{props.label}</label>
      {props?.toggle && isPasswordVisible && !props?.error && <img src={IconOn} alt='IconOn' className={Style['post-icon']} onClick={() => setIsPasswordVisible(false)} />}
      {props?.toggle && !isPasswordVisible && !props?.error && <img src={IconOff} alt='IconOff' className={Style['post-icon']} onClick={() => setIsPasswordVisible(true)} />}
      {props?.error && props?.shouldErrorIconVisible && <img src={IconError} alt='IconError' className={Style['post-icon']} />}
      {props?.error && <p className={Style['error']}>{props.error}</p>}
    </div>
  </React.Fragment>
}

export default FieldPassword
