import React from 'react'
import Style from './FieldNumber.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form'
import IconError from '../../../assets/icons/icon_error_circle.svg'

interface Props {
  formContolName: UseFormRegisterReturn<string>
  name: string
  label: string
  error?: string
  toggle?: boolean
  shouldErrorIconVisible?: boolean
}

const FieldNumber = (props: Props) => {
  const valNumber = () => {

  }

  return <React.Fragment>
    <div className={Style['container']}>
      <input type="text" {...props.formContolName} id={props.name} className={props?.error ? Style['error'] : ''} name={props.name} placeholder='' />
      <label className={props?.error ? Style['error'] : ''} htmlFor={props.name}>{props.label}</label>
      {props?.error && props?.shouldErrorIconVisible && <img src={IconError} alt='IconError' className={Style['post-icon']} />}
      {props?.error && <p className={Style['error']}>{props.error}</p>}
    </div>
  </React.Fragment>
}

export default FieldNumber
