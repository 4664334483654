import React from 'react'
import Style from './HeaderUserActionsPopup.module.scss'
import IconProfile from '../../../assets/icons/icon_profile.svg'
import Setting from '../../../assets/icons/icon_setting.svg'
import Logout from '../../../assets/icons/icon_logout.svg'
import Button from '../../atoms/button/Button'
import { ProviderUserAuthService } from '../../providers/provider-user-auth.service'
import { ReducerUserAuthServiceActions } from '../../reducers/reducer-user-auth.service'
import { useHistory } from 'react-router-dom'

const HeaderUserActionsPopup = () => {
  const history = useHistory()

  const signOut = () => {
    ProviderUserAuthService.signOut()
    ReducerUserAuthServiceActions.setUserAuthAccountValueWithoutRedux(null)
    ReducerUserAuthServiceActions.navToUserRoute(history)
  }

  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['row']}>
        <div className={Style['btn']}>
          <img src={IconProfile}></img>
          <p>Profile</p>
        </div>
        <div className={Style['btn']}>
          <img src={Setting}></img>
          <p>Settings</p>
        </div>
      </div>
      <Button preIcon={Logout} handleClick={signOut}>Logout from this device</Button>
    </div>
  </React.Fragment >
}

export default HeaderUserActionsPopup
