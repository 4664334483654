import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { USER_AUTH_ROLE } from '../types/providers/type-user-auth'
import { ReducerUserAuthServiceActions } from '../reducers/reducer-user-auth.service'

interface Props {
  children: JSX.Element
}
const ClientGuardCanActivate = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const [visibility, setVisibility] = useState(false)

  useEffect(() => {
    const userAuth = ReducerUserAuthServiceActions.getUserAuthAccountValue()
    if (userAuth) {
      const userAuthRole = userAuth.data.user_auth_role
      if (userAuthRole !== USER_AUTH_ROLE.Client) {
        history.replace('/on-board')
      } else {
        setVisibility(true)
      }
    } else { history.replace('/on-board') }
  }, [location.pathname])

  return <React.Fragment>
    {visibility && props.children}
  </React.Fragment>
}

export default ClientGuardCanActivate