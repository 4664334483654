import React, { useState } from "react"
import Style from "./AddLayout.module.scss"
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from "react-redux"
import { AxiosError, AxiosResponse } from "axios"
import { TypeAPIErrorResponse, TypeAPIAddResponse } from "../../../types/type-api"
import { ReducerAPILoaderServiceActions } from "../../../reducers/reducer-api-loader.service"
import { FieldValues, RegisterOptions, useForm } from "react-hook-form"
import { APP_TOAST_TYPE, TypeToastOptions } from "../../../atoms/toast/Toast"
import { ReducerAppToastServiceActions } from "../../../reducers/reducer-app-toast.service"
import { useHistory } from "react-router-dom"

interface Props {
    children: any,
    formControlNameOption: (control: string) => RegisterOptions
    fValid: (fValues: FieldValues) => object,
    fInValid: (fValues: FieldValues) => void,
    add: (params?: object) => Promise<AxiosResponse<TypeAPIAddResponse>>,
    extraProps?: object
}

const AddLayout = (props: Props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const form = useForm({ mode: 'onBlur' })
    const [isLoading, setIsLoading] = useState<boolean>()

    const formControlName = (control: string) => {
        const option = props.formControlNameOption(control)
        return form.register(control, option)
    }

    const getErrorMessage = (field: string): string => {
        const errMsg: any = form.formState.errors?.[field]?.message
        if (!form.formState.submitCount) return ''
        return errMsg ? errMsg : ''
    }

    const onFormSubmit = (e: any) => {
        e.preventDefault()
        form.handleSubmit((fValues: FieldValues) => {
            add(props.fValid(fValues))
        }, (fValues: FieldValues) => {
            props.fInValid(fValues)
        })()
    }

    const add = (params: object) => {
        if (isLoading) return
        setIsLoading(true)
        dispatch(ReducerAPILoaderServiceActions.pushAPILoading())

        props.add({ ...params, uuid: uuidv4() }).then((res) => {
            history.goBack()
            dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Success, message: res?.data.message } as TypeToastOptions))
        }).catch((err: AxiosError<TypeAPIErrorResponse>) => {
            dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Error, message: err.response?.data.message } as TypeToastOptions))
        }).finally(() => {
            dispatch(ReducerAPILoaderServiceActions.popAPILoading())
            setIsLoading(false)
        })
    }

    return <React.Fragment>
        <div className={Style['container']}>
            {props.children({ form, formControlName, getErrorMessage, onFormSubmit, extra: props.extraProps })}
        </div>
    </React.Fragment>
}

export default AddLayout