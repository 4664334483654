import Style from "./ProductInfoCardComponent.module.scss"
import React from "react"
import IconDot from "../../../assets/icons/icon_more_vert.svg"
import ImgThumbnail from "../../../assets/images/img_thumbnail.svg"
import { TypeUserProduct } from "../../types/providers/type-user-product"

interface Props {
  product: TypeUserProduct
}

const ProductInfoCardComponent = (props: Props) => {
  return <React.Fragment>
    <div className={Style["container"]}>
      <div className={Style["product-img"]}>
        <img src={ImgThumbnail} alt="ImgThumbnail" />
      </div>
      <div className={Style["containt"]}>
        <div className={Style["text-part"]}>
          <p className={Style["heading"]}>{props.product.name} - {props.product.net_quantity}{props.product.unit}</p>
          <p className={Style["sub-heading"]}>MRP - ₹{props.product.price}</p>
        </div>
      </div>
      <div className={Style["icon"]}>
        <img src={IconDot} alt="Three dot icons" />
      </div>
    </div>
  </React.Fragment>
}
export default ProductInfoCardComponent
