import React from 'react'
import Style from './RootLayout.module.scss'

interface Props {
  children: JSX.Element
}

const RootLayout = (props: Props) => {
  return <React.Fragment>
    <div className={Style['container']}>
      {props.children}
    </div>
  </React.Fragment>
}

export default RootLayout