import { AxiosResponse } from 'axios'
import { helperHttpAxios } from '../helpers/helper-http-axios'
import { TypeUserEmployee } from '../types/providers/type-user-employee'
import { TypeAPIAddResponse, TypeAPIErrorResponse, TypeAPIGetListLengthResponse, TypeAPIGetListResponse } from '../types/type-api'

const API_URL = '/api/user/employee/'

const add = (params: object = {}): Promise<AxiosResponse<TypeAPIAddResponse, TypeAPIErrorResponse>> => {
    return helperHttpAxios.post(API_URL + 'add', params)
}

const getList = (params: object = {}): Promise<AxiosResponse<TypeAPIGetListResponse<TypeUserEmployee>, TypeAPIErrorResponse>> => {
    // return helperHttpAxios.get(API_URL + 'get-list', { params })

    return new Promise((resolve) => {
        resolve({
            data: {
                "type": "success",
                "message": "Stores Fetched Successfully",
                "data": [
                    {
                        "id": 14,
                        "uuid": "a317489e-2aab-4c32-b2b6-aceb0cc068ef",
                        "timestamp": "2024-09-17 08:34:58"
                    },
                    {
                        "id": 13,
                        "uuid": "634cf856-d9eb-4be0-9c89-65fa2a570cf6",
                        "timestamp": "2024-09-17 08:34:07"
                    },
                    {
                        "id": 12,
                        "uuid": "ce868c1f-c73c-41c0-b8e2-39388be45067",
                        "timestamp": "2024-09-13 14:12:35"
                    }
                ]
            } as TypeAPIGetListResponse<TypeUserEmployee>
        } as AxiosResponse)
    })
}

const getListLength = (params: object = {}): Promise<AxiosResponse<TypeAPIGetListLengthResponse, TypeAPIErrorResponse>> => {
    // return helperHttpAxios.get(API_URL + 'get-list-length', { params })

    return new Promise((resolve) => {
        resolve({
            data: {
                "type": "success",
                "message": "Stores Length Fetched Successfully",
                "data": {
                    "length": 14
                }
            } as TypeAPIGetListResponse<TypeAPIGetListLengthResponse>
        } as AxiosResponse)
    })
}

export const ProviderUserEmployeeService = {
    add,
    getList,
    getListLength
}