import React from 'react'
import Style from './AuthLayout.module.scss'
import ImgLogoBmClient from '../../../assets/images/img_logo-bm_client.svg'
import ImgLogoBmClientBm from '../../../assets/images/img_logo-bm_client_bm.svg'
import ImgLogoBmClientDm from '../../../assets/images/img_logo-bm_client_dm.svg'
import ImgLogoBmClientIm from '../../../assets/images/img_logo-bm_client_im.svg'
import ImgLogoBmClientOm from '../../../assets/images/img_logo-bm_client_om.svg'
import { USER_AUTH_ROLE } from '../../types/providers/type-user-auth'

interface Props {
  role: string
  children: JSX.Element
}

const AuthLayout = (props: Props) => {
  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['logo-brand-wrapper']}>
        {props.role === USER_AUTH_ROLE.Client && <img src={ImgLogoBmClient} alt='ImgLogoBmClient' />}
        {props.role === USER_AUTH_ROLE.ClientBm && <img src={ImgLogoBmClientBm} alt='ImgLogoBmClientBm' />}
        {props.role === USER_AUTH_ROLE.ClientDm && <img src={ImgLogoBmClientDm} alt='ImgLogoBmClientDm' />}
        {props.role === USER_AUTH_ROLE.ClientIm && <img src={ImgLogoBmClientIm} alt='ImgLogoBmClientIm' />}
        {props.role === USER_AUTH_ROLE.ClientOm && <img src={ImgLogoBmClientOm} alt='ImgLogoBmClientOm' />}
      </div>
      {props.children}
    </div>
  </React.Fragment>
}

export default AuthLayout
