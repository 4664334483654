import React from "react"
import Style from "./StoreAddPage.module.scss"
import AddLayout from "../../layouts/client/add/AddLayout"
import { useHistory } from "react-router-dom"
import { RegisterOptions, FieldValues } from "react-hook-form"
import { ProviderUserStoreService } from "../../providers/provider-user-store.service"
import { CONST_REG_EX } from "../../types/type-reg-ex"
import HeaderFormComponent, { HeaderFormStep } from "../../components/header-form/HeaderFormComponent"
import FieldText from "../../atoms/field-text/FieldText"
import Button, { ButtonType } from "../../atoms/button/Button"

enum FORM_CONTROL {
    name = 'name',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Address = 'address',
    Pincode = 'pincode',
    City = 'city',
    State = 'state'
}

const AddForm = (props: any) => {
    const history = useHistory()
    const HeaderFormSteps: HeaderFormStep[] = [
        {
            name: 'AddNewStore',
            label: 'Add new store'
        }
    ]

    return <React.Fragment>
        <div className={Style['container']}>
            <HeaderFormComponent active={HeaderFormSteps[0]} steps={HeaderFormSteps} />
            <p className={Style['sub-heading']}>Required fields are marked with an asterisk *</p>
            <form className={Style['form']} onSubmit={props.onFormSubmit}>
                <div className={Style['row']}>
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.name)} error={props.getErrorMessage(FORM_CONTROL.name)} name={FORM_CONTROL.name} label="Store name *" />
                </div>
                <div className={Style['row']}>
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.PhoneNumber)} error={props.getErrorMessage(FORM_CONTROL.PhoneNumber)} name={FORM_CONTROL.PhoneNumber} label="Phone number *" />
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.Email)} error={props.getErrorMessage(FORM_CONTROL.Email)} name={FORM_CONTROL.Email} label='Email ' />
                </div>
                <div className={Style['row']}>
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.Address)} error={props.getErrorMessage(FORM_CONTROL.Address)} name={FORM_CONTROL.Address} label="Address *" />
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.Pincode)} error={props.getErrorMessage(FORM_CONTROL.Pincode)} name={FORM_CONTROL.Pincode} label='Pin code *' />
                </div>
                <div className={Style['row']}>
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.City)} error={props.getErrorMessage(FORM_CONTROL.City)} name={FORM_CONTROL.City} label="City *" />
                    <FieldText formContolName={props.formControlName(FORM_CONTROL.State)} error={props.getErrorMessage(FORM_CONTROL.State)} name={FORM_CONTROL.State} label='State *' />
                </div>
                <div className={Style['row-btn']}>
                    <Button handleClick={() => history.goBack()}>Cancel</Button>
                    <Button type={ButtonType.Submit}>Create</Button>
                </div>
            </form>
        </div>
    </React.Fragment>
}

const StoreAddPage = () => {
    const formControlNameOption = (control: string) => {
        let option: RegisterOptions = {}
        switch (control) {
            case FORM_CONTROL.name: {
                option = {
                    required: { value: true, message: "Store Name Is Required" }
                }
                break
            }
            case FORM_CONTROL.PhoneNumber: {
                option = {
                    required: { value: true, message: "Phone Number Is Required" },
                    pattern: { value: CONST_REG_EX.Phone, message: 'Invalid Phone Number' },
                }
                break
            }
            case FORM_CONTROL.Email: {
                option = {
                    required: { value: true, message: "Email Is Required" },
                    pattern: { value: CONST_REG_EX.Email, message: 'Invalid Email address' },
                }
                break
            }
            case FORM_CONTROL.Pincode: {
                option = {
                    required: { value: true, message: "Pincode Is Required" },
                }
                break
            }
            case FORM_CONTROL.Address: {
                option = {
                    required: { value: true, message: "Address Is Required" },
                }
                break
            }
            case FORM_CONTROL.City: {
                option = {
                    required: { value: true, message: "City Is Required" },
                }
                break
            }
            case FORM_CONTROL.State: {
                option = {
                    required: { value: true, message: "State Is Required" },
                }
                break
            }
        }
        return option
    }

    const fInValid = (fValues: FieldValues) => { }

    const fValid = (fValues: FieldValues): object => {
        return {
            name: fValues[FORM_CONTROL.name],
            phone: fValues[FORM_CONTROL.PhoneNumber],
            email: fValues[FORM_CONTROL.Email],
            address: fValues[FORM_CONTROL.Address],
            pincode: fValues[FORM_CONTROL.Pincode],
            city: fValues[FORM_CONTROL.City],
            state: fValues[FORM_CONTROL.State]
        }
    }

    return <React.Fragment>
        <AddLayout formControlNameOption={formControlNameOption} fInValid={fInValid} fValid={fValid} add={ProviderUserStoreService.add}>
            {(props: any) => AddForm({ ...props })}
        </AddLayout>
    </React.Fragment >
}

export default StoreAddPage