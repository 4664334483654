import React, { forwardRef } from 'react'
import Style from './BillSlipComponent.module.scss'
import { useSelector } from 'react-redux'
import { TypeReducerState } from '../../../redux-store'

interface Props {
  bill: any
}

const BillSlipComponent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const storeActiveValue = useSelector((state: TypeReducerState) => state.appConfig.storeActiveValue)

  return <React.Fragment>
    <div className={Style['container']} ref={ref}>
      <h4 className={Style['title']}>{storeActiveValue.name}</h4>
      <address>{storeActiveValue.address}<br />{storeActiveValue.state}</address>
      <div className={Style['tax-invoice-wrapper']}>
        <hr className={Style['dash']} />
        <span>TAX&nbspINVOICE</span>
        <hr className={Style['dash']} />
      </div>
      <div className={Style['head-info-wrapper']}>
        <p className={Style['date']}>
          <span>Date : {props.bill['date'] || '?'}</span>
          <span>Time : {props.bill['time'] || '?'}</span>
        </p>
        <p className={Style['number']}>
          Bill No. :
          <span className={Style['bold']}>{props.bill['id'] || '?'}</span>
        </p>
      </div>
      <hr className={Style['dash']} />
      <div className={Style['item']}>
        <p>Particulars</p>
        <p>Qty</p>
        <p>Rate</p>
        <p>Amt</p>
      </div>
      <hr className={Style['dash']} />
      {props.bill['products'].map((product: any) => <div className={Style['item']} key={product.uuid}>
        <p>{product.name}</p>
        <p>{product.noOfItems}</p>
        <p>{product.price}</p>
        <p>{product.noOfItems * product.price}</p>
      </div>)}
      <hr className={Style['dash']} />
      {props.bill['discountPercentage'] && <React.Fragment>
        <p className={Style['sub-total']}>
          <span>Sub Total :</span>
          <span>{props.bill['subTotal']}</span>
        </p>
        <p className={Style['discount']}>
          <span>Discount :</span>
          <span>({props.bill['discountPercentage']}%) -{props.bill['discountAmount']}</span>
        </p>
      </React.Fragment>}
      <p className={Style['total']}>
        <span>Total :</span>
        <span>{props.bill['total']}</span>
      </p>
      <hr className={Style['dash']} />
      <p className={Style['contact']}>
        <span>Contact Us: 9760701850, 9045538404</span>
      </p>
      <hr className={Style['dash']} />
      <h4 className={Style['title']}>Thank You! Visit Again!</h4>
    </div>
  </React.Fragment>
})

export default BillSlipComponent
