import React, { useState } from 'react'
import Style from './FieldSearch.module.scss'
import IconSearch from "../../../../src/assets/icons/icon_fieldSearch.svg"
import IconClose from "../../../../src/assets/icons/icon_close.svg"

interface Props {
  handleSearch: (text: string) => void
}

const FieldSearch = (props: Props) => {
  const [search, setSearch] = useState('')

  const handleSearch = (text: string) => {
    if (!text) setSearch('')
    props.handleSearch(text)
  }

  return <React.Fragment>
    <div className={Style['form-group']}>
      <div className={Style['input-group']}>
        <input type="text" placeholder="Hinted search text" value={search} onChange={e => setSearch(e.target.value.trim())} onKeyDown={e => e.code === 'Enter' && handleSearch(search)} />
        {search && <img src={IconClose} alt="IconClose" onClick={() => handleSearch('')} />}
        {!search && <img src={IconSearch} alt="IconSearch" />}
      </div>
    </div>
  </React.Fragment>
}

export default FieldSearch
