
import React from 'react'
import Style from './BillInfoCardComponent.module.scss'
import { TypeUserBill } from '../../types/providers/type-user-bill'
import IconIconBillInfoCardPaid from '../../../assets/icons/icon_IconBillInfoCardPaid.svg'
import { TypeUserProductSelected } from '../../types/providers/type-user-product'

interface Props {
    bill: TypeUserBill
}

const BillInfoCardComponent = (props: Props) => {
    const products: TypeUserProductSelected[] = JSON.parse(props.bill.products)

    return <React.Fragment>
        <div className={Style['container']}>
            <div className={Style['text-part']}>
                <small>Dine In {props.bill.timestamp}</small>
                <p>{products[0].name}{products.length > 2 ? ` + ${products.length - 1} items` : ''}</p>
                <small>Price - ₹ {props.bill.total}</small>
            </div>
            <img src={IconIconBillInfoCardPaid} alt='IconIconBillInfoCardPaid' />
        </div>
    </React.Fragment>
}
export default BillInfoCardComponent


