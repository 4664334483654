import React from "react"
import Style from "./ProductCounterCardComponent.module.scss"
import IconAdd from "../../../assets/icons/icon_add-333333.svg"
import ImgThumbnail from "../../../assets/images/img_thumbnail.svg"
import IconRemove from "../../../assets/icons/icon_remove.svg"
import { TypeUserProduct, TypeUserProductSelected } from "../../types/providers/type-user-product"

interface Props {
  productSelected: TypeUserProductSelected
  handleProduct: (product: TypeUserProduct, num: number) => void
}

const ProductcounterCardComponent = (props: Props) => {
  return <React.Fragment>
    <div className={Style["container"]}>
      <div className={Style["product-img"]}>
        <img src={ImgThumbnail} alt="ImgThumbnail" />
      </div>
      <div className={Style["containt"]}>
        <div className={Style["text-part"]}>
          <p className={Style["heading"]}>{props.productSelected.name} - {props.productSelected.net_quantity}{props.productSelected.unit}</p>
          <p className={Style["sub-heading"]}>MRP - ₹{props.productSelected.price}</p>
        </div>
      </div>
      <div className={Style["end-part"]}>
        <button className={Style["add-icon"]} onClick={() => props.handleProduct(props.productSelected, 1)}>
          <img className={Style["img"]} src={IconAdd} alt="Add icon" />
          <p>x{props.productSelected.noOfItems}</p>
        </button>
        <button className={Style["remove-icon"]} onClick={() => props.handleProduct(props.productSelected, -1)}>
          <img src={IconRemove} alt="remove icon" />
        </button>
      </div>
    </div>
  </React.Fragment>
}
export default ProductcounterCardComponent
