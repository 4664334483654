import * as H from 'history'
import { createSlice } from "@reduxjs/toolkit"
import { TypeAPISignInResponse, USER_AUTH_ROLE } from "../types/providers/type-user-auth"
import { CONST_STORAGE_KEY } from "../types/type-storage-key"

export interface TypeUserAuthInitialState {
    accountKey: string
    accountValue: TypeAPISignInResponse
}

const initialState: TypeUserAuthInitialState = {
    accountKey: CONST_STORAGE_KEY.User.AUTHAccount,
    accountValue: JSON.parse(localStorage.getItem(CONST_STORAGE_KEY.User.AUTHAccount) as string) as TypeAPISignInResponse
}

const slice = createSlice({
    name: "userAuth",
    initialState,
    reducers: {
        setUserAuthAccountValue: (state, { payload }) => {
            state.accountValue = payload as TypeAPISignInResponse
            setUserAuthAccountValueWithoutRedux(payload as TypeAPISignInResponse)
        }
    }
})

const getUserAuthAccountValue = (): TypeAPISignInResponse => {
    return JSON.parse(localStorage.getItem(CONST_STORAGE_KEY.User.AUTHAccount) as string) as TypeAPISignInResponse
}

const setUserAuthAccountValueWithoutRedux = (payload?: any) => {
    if (payload) {
        localStorage.setItem(CONST_STORAGE_KEY.User.AUTHAccount, JSON.stringify(payload))
    } else {
        Object.values(CONST_STORAGE_KEY.User).forEach(key => localStorage.removeItem(key))
    }
}

const navToUserRoute = (history?: H.History) => {
    const userAuthAccountValue: TypeAPISignInResponse = getUserAuthAccountValue()
    const userAuthRole = userAuthAccountValue?.data?.user_auth_role
    if (userAuthRole === USER_AUTH_ROLE.ClientIm) {
        history ? history.replace('/client') : window.location.href = window.location.origin + '/client'
    } else {
        history ? history.replace('/on-board') : window.location.href = window.location.origin + '/on-board'
    }
}

export const ReducerUserAuthServiceActions = {
    navToUserRoute,
    getUserAuthAccountValue,
    setUserAuthAccountValueWithoutRedux,
    ...slice.actions,
}

export const ReducerUserAuthService = slice.reducer