import { TypeAPIResponse } from "../type-api"

export enum USER_AUTH_ROLE {
    Client = "client",
    ClientBm = "client-bm",
    ClientDm = "client-dm",
    ClientIm = "client-im",
    ClientOm = "client-om"
}

export interface TypeUserAuthAccount {
    id: number
    uuid: string
    name: string
    image: string
    email: string
    phone: string
    timestamp: string
}

export interface TypeAPISignInData extends TypeUserAuthAccount {
    jwt: string
    user_auth_role: USER_AUTH_ROLE
}

export interface TypeAPISignInResponse extends TypeAPIResponse<TypeAPISignInData> { }
export interface TypeAPISignOutResponse extends TypeAPIResponse<[]> { }
export interface TypeAPIForgotPasswordResponse extends TypeAPIResponse<[]> { }
export interface TypeAPIResetPasswordResponse extends TypeAPIResponse<[]> { }