import React, { useState } from "react"
import Style from "./EmployeeListPage.module.scss"
import { ProviderUserEmployeeService } from "../../providers/provider-user-employee.service"
import ListLayout from "../../layouts/client/list/ListLayout"
import EmployeeInfoCardComponent from "../../components/employee-info-card/EmployeeInfoCardComponent"
import { TypeUserEmployee } from "../../types/providers/type-user-employee"


const EmployeeListPage = () => {
    const [employeeList, setEmployeeList] = useState<unknown[]>([])

    return <React.Fragment>
        <div className={Style['container']}>
            <ListLayout name="Employee" list={employeeList} setList={setEmployeeList} getList={ProviderUserEmployeeService.getList} getListLength={ProviderUserEmployeeService.getListLength}>
                <React.Fragment>
                    {(employeeList as TypeUserEmployee[]).map(employee => <EmployeeInfoCardComponent key={employee.uuid} employee={employee} />)}
                </React.Fragment>
            </ListLayout>
        </div>
    </React.Fragment >
}

export default EmployeeListPage