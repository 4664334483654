import React from "react"
import Style from "./ProgressBar.module.scss"

interface Props {
    type: "indeterminate"
}

const ProgressBar = (props: Props) => {
    return <React.Fragment>
        <div title={props.type} role="progress-bar" className={`${Style['p-progress-bar']} ${Style['p-progress-bar-indeterminate']}`} >
            <div className={`${Style['p-progress-bar-value']}`}></div>
        </div>
    </React.Fragment>
}

export default ProgressBar
