import React from 'react'
import Style from './ClientLayout.module.scss'
import HeaderComponent from '../../components/header/HeaderComponent'
import HeaderStripComponent from '../../components/header-strip/HeaderStripComponent'
import MenuComponent from '../../components/menu/MenuComponent'
import RightSectionClientListLayoutComponent from '../../components/right-section-client-list-layout/RightSectionClientListLayoutComponent'
import { helperIsListPageActiveRoute } from '../../helpers/helper-location'
import { useLocation } from 'react-router-dom'

interface Props {
  children: JSX.Element
}

const ClientLayout = (props: Props) => {
  const location = useLocation()
  const isListPageActiveRoute = helperIsListPageActiveRoute(location)

  return <React.Fragment>
    <div className={Style['container']}>
      <div className={Style['head']}>
        <HeaderComponent />
        <HeaderStripComponent />
      </div>
      <div className={Style['body']}>
        <MenuComponent />
        <div className={Style['content']}>
          {props.children}
        </div>
        <div className={Style['right']}>
          {isListPageActiveRoute && <RightSectionClientListLayoutComponent />}
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default ClientLayout

