import { AxiosResponse } from 'axios'
import { helperHttpAxios } from '../helpers/helper-http-axios'
import {
    TypeAPISignInResponse,
    TypeAPISignOutResponse,
    TypeAPIForgotPasswordResponse,
    TypeAPIResetPasswordResponse
} from '../types/providers/type-user-auth'
import { TypeAPIErrorResponse } from '../types/type-api'
import { ReducerUserAuthServiceActions } from '../reducers/reducer-user-auth.service'

const API_URL = '/api/user/auth/'

const signIn = (params: object = {}): Promise<AxiosResponse<TypeAPISignInResponse, TypeAPIErrorResponse>> => {
    return helperHttpAxios.post(API_URL + 'sign-in', params)
}

const signOut = (params: object = {}): Promise<AxiosResponse<TypeAPISignOutResponse, TypeAPIErrorResponse>> => {
    params = { jwt: ReducerUserAuthServiceActions.getUserAuthAccountValue()?.data?.jwt }

    return helperHttpAxios.post(API_URL + 'sign-out', params)
}

const forgotPassword = (params: object = {}): Promise<AxiosResponse<TypeAPIForgotPasswordResponse, TypeAPIErrorResponse>> => {
    return helperHttpAxios.post(API_URL + 'forgot-password', params)
}

const resetPassword = (params: object = {}): Promise<AxiosResponse<TypeAPIResetPasswordResponse, TypeAPIErrorResponse>> => {
    return helperHttpAxios.post(API_URL + 'reset-password', params)
}

export const ProviderUserAuthService = {
    signIn,
    signOut,
    forgotPassword,
    resetPassword,
}