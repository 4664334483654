import React from 'react'
import Style from './HeaderFormComponent.module.scss'

export interface HeaderFormStep {
  name: string,
  label: string
}

interface Props {
  active: HeaderFormStep
  steps: HeaderFormStep[]
}

const HeaderFormComponent = (props: Props) => {
  return <React.Fragment>
    <div className={Style["container"]}>
      {props.steps.map((step, index) => <React.Fragment>
        <h4 className={Style["label"]}>{step.label}</h4>
        {index !== props.steps.length - 1 && <div className={Style["line"]} />}
      </React.Fragment>)}
    </div>
  </React.Fragment>
}

export default HeaderFormComponent
