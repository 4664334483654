import React, { useEffect, useState } from "react"
import Style from "./ListLayout.module.scss"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { AxiosError, AxiosResponse } from "axios"
import { TypeLooseObject } from "../../../types/type-loose-object"
import { TypeAPIErrorResponse, TypeAPIGetListLengthResponse, TypeAPIGetListResponse } from "../../../types/type-api"
import { helperApplySearch, helperGetQueryParams, helperIsQueryParamsExist } from "../../../helpers/helper-location"
import { ReducerAPILoaderServiceActions } from "../../../reducers/reducer-api-loader.service"
import Spinner from "../../../atoms/spinner/Spinner"
import NoDataFoundComponent from "../../../components/no-data-found/NoDataFoundComponent"
import DataLoadError from "../../../components/data-load-error/DataLoadErrorComponent"
import FieldSearch from "../../../atoms/field-search/FieldSearch"

interface Props {
    children: JSX.Element
    name: string,
    list: unknown[],
    setList: React.Dispatch<React.SetStateAction<unknown[]>>,
    getList: (params?: object) => Promise<AxiosResponse<TypeAPIGetListResponse<unknown>>>,
    getListLength: (params?: object) => Promise<AxiosResponse<TypeAPIGetListLengthResponse>>,
}

const ListLayout = (props: Props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const [listLength, setListLength] = useState(0)
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isLoadingListLength, setIsLoadingListLength] = useState<boolean>()
    const [isListLastItemFetched, setIsListLastItemFetched] = useState<boolean>()
    const [listFilterParams, setListFilterParams] = useState<TypeLooseObject<string>>({})
    const [isDataNotFound, setIsDataNotFound] = useState<boolean>()
    const [apiError, setApiError] = useState<string>()

    const onScrollEnd = (event: any) => {
        const element = event.target as HTMLElement
        if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
            !isListLastItemFetched && getList(props.list.length, 20, listFilterParams)
        }
    }

    const getList = (index: number = 0, length: number = 20, filter: TypeLooseObject<string> = {}) => {
        if (isLoading) return
        setIsLoading(true)
        dispatch(ReducerAPILoaderServiceActions.pushAPILoading())

        const param = {
            index,
            length,
            filters: JSON.stringify(filter),
            sort_key: 'timestamp',
            sort_type: 'DESC'
        }

        props.getList(param).then((res) => {
            index === 0 ? props.setList(res.data.data) : props.setList([...props.list, ...res.data.data])
            setIsListLastItemFetched(res.data.data.length === 0)
            setIsDataNotFound(index === 0 && res.data.data.length === 0)
            setApiError(undefined)
        }).catch((err: AxiosError<TypeAPIErrorResponse>) => {
            setApiError(err.response?.data.message as string)
        }).finally(() => {
            dispatch(ReducerAPILoaderServiceActions.popAPILoading())
            setIsLoading(false)
        })
    }

    const getListLength = (filter: TypeLooseObject<string> = {}) => {
        if (isLoadingListLength) return
        setIsLoadingListLength(true)
        dispatch(ReducerAPILoaderServiceActions.pushAPILoading())

        const param = { filters: JSON.stringify(filter) }
        props.getListLength(param).then((res) => {
            setListLength(res.data.data.length)
            setApiError(undefined)
        }).catch((err: AxiosError<TypeAPIErrorResponse>) => {
            setApiError(err.response?.data.message as string)
        }).finally(() => {
            dispatch(ReducerAPILoaderServiceActions.popAPILoading())
            setIsLoadingListLength(false)
        })
    }

    const getListByFilter = (field?: string, value?: string, filterParams?: TypeLooseObject<string>) => {
        let filter = field ? { ...listFilterParams, [field]: value } : filterParams ? filterParams : listFilterParams
        setListFilterParams(filter)
        getList(0, 20, filter)
        getListLength(filter)
    }

    useEffect(() => {
        const filterParams: TypeLooseObject<string> = {}
        if (helperIsQueryParamsExist(location, ['search']))
            filterParams.name = helperGetQueryParams(location, 'search')
        getListByFilter(undefined, undefined, filterParams)
    }, [location.search])

    return <React.Fragment>
        <div className={Style["container"]}>
            <div className={Style["header"]}>
                <h4 className={Style["title"]}>{props.name}{listLength >= 2 && `(s) - ${listLength}`}</h4>
                <FieldSearch handleSearch={text => helperApplySearch(history, location, text)} />
            </div>
            <div className={Style['list']} onScroll={onScrollEnd}>
                {!apiError && !isDataNotFound && props.children}
                <div className={Style['bottom-safe-space']}></div>
                {isLoading && <div className={Style['spinner-wrapper']}><Spinner /></div>}
                {!isLoading && isDataNotFound && <NoDataFoundComponent msg={`${props.name} Not Found`} handleAdd={() => history.push(`add`)} />}
                {!isLoading && apiError && <DataLoadError msg={apiError} handleRefresh={() => getListByFilter()} />}
            </div>
        </div>
    </React.Fragment>
}

export default ListLayout