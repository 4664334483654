import React from "react"
import ReactDOM from 'react-dom'
import Style from "./AppPopup.module.scss"

interface Props {
    top?: string
    right?: string
    bottom?: string
    left?: string
    children: JSX.Element
}

function AppPopup(props: Props) {
    return ReactDOM.createPortal(
        <React.Fragment>
            <div className={Style['container']} style={{ top: props.top, right: props.right, bottom: props.bottom, left: props.left }}>
                {props.children}
            </div>
        </React.Fragment>,
        document.getElementById('root-popup') as HTMLElement
    )
}

export default AppPopup