import React from 'react'
import Style from './OnBoardPage.module.scss'
import Button from '../../atoms/button/Button'
import ImgLogo from '../../../assets/images/img_logo.svg'
import { useHistory } from 'react-router-dom'

const OnBoardPage = () => {
    const history = useHistory()

    return <React.Fragment>
        <div className={Style['container']}>
            <a href={`${process.env.REACT_APP_ASPL_INFO_REDIRECT_URL}`} target='_Blank' rel="noreferrer">
                <img src={ImgLogo} alt='ImgLogo' />
            </a>
            <div className={Style['content']}>
                <h1>Boost Your Business <br></br>Faster</h1>
                <p>Elevate your business swiftly with our innovative solutions, optimizing efficiency and fostering rapid growth. From streamlined operations to dynamic marketing, we're committed to accelerating your success.</p>
                <Button handleClick={() => history.push("/auth/sign-in?role=client")}>Get Started</Button>
            </div>
        </div>
    </React.Fragment>
}

export default OnBoardPage