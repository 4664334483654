import React, { useEffect, useState } from "react"
import Style from "./BillAddPage.module.scss"
import { TypeUserProduct, TypeUserProductSelected } from "../../types/providers/type-user-product"
import { ProviderUserProductService } from "../../providers/provider-user-product.service"
import ListLayout from "../../layouts/client/list/ListLayout"
import Button from "../../atoms/button/Button"
import { useHistory, useLocation } from "react-router-dom"
import { v4 as uuidV4 } from 'uuid'
import ProductAddCardComponent from "../../components/product-add-card/ProductAddCardComponent"
import ProductCounterCardComponent from "../../components/product-counter-card/ProductCounterCardComponent"
import { useDispatch } from "react-redux"
import { ReducerAppToastServiceActions } from "../../reducers/reducer-app-toast.service"
import { APP_TOAST_TYPE, TypeToastOptions } from "../../atoms/toast/Toast"

const BillAddPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(useLocation().search)
    const [availableProductList, setAvailableProductList] = useState<unknown[]>([])
    const [selectedProductList, setSelectedProductList] = useState<TypeUserProductSelected[]>([])

    useEffect(() => {
        if (queryParams.get('bill')) {
            const bill = JSON.parse(localStorage.getItem('bill-' + queryParams.get('bill')) as string)
            if (!bill) {
                dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Info, message: 'Link Expire Add New Bill' } as TypeToastOptions))
                history.replace("/client/bill/add")
            } else {
                setSelectedProductList(bill['products'])
            }
        }
    }, [])

    const handleProduct = (product: TypeUserProduct, num: number) => {
        setSelectedProductList(perList => {
            const index = perList.findIndex(p => p.uuid === product.uuid)
            if (index !== -1) {
                if (perList[index].noOfItems <= 1 && num < 0) {
                    perList.splice(index, 1)
                } else {
                    perList[index].noOfItems += num
                }
            } else {
                perList.unshift({
                    ...product,
                    noOfItems: 1
                })
            }
            return [...perList]
        })
    }

    const isVisibleProduct = (product: TypeUserProduct): boolean => {
        return !selectedProductList.find(p => p.uuid === product.uuid)
    }

    const handleBillForPrint = () => {
        if (selectedProductList.length === 0) {
            dispatch(ReducerAppToastServiceActions.showToast({ type: APP_TOAST_TYPE.Info, message: 'Select Product First' } as TypeToastOptions))
            return
        }

        const payload = {
            uuid: queryParams.get('bill') ?? uuidV4(),
            products: selectedProductList
        }
        localStorage.setItem('bill-' + payload.uuid, JSON.stringify(payload))
        history.replace('/client/bill/add-preview?bill=' + payload.uuid)
    }


    return <React.Fragment>
        <div className={Style['container']}>
            <div className={Style['avilable-item-wrapper']}>
                <ListLayout name=" Product" list={availableProductList} setList={setAvailableProductList} getList={ProviderUserProductService.getList} getListLength={ProviderUserProductService.getListLength}>
                    <React.Fragment>
                        {(availableProductList as TypeUserProduct[]).map(product => <ProductAddCardComponent key={product.uuid} product={product} handleProduct={handleProduct} isVisibleProduct={isVisibleProduct(product)} />)}
                    </React.Fragment>
                </ListLayout>
            </div>
            <div className={Style['seleted-item-wrapper']}>
                <div className={Style['row']}>
                    <Button handleClick={() => handleBillForPrint()}>Generate Bill</Button>
                </div>
                <div className={Style['list']}>
                    {(selectedProductList as TypeUserProductSelected[]).map(productSelected => <ProductCounterCardComponent key={productSelected.uuid} productSelected={productSelected} handleProduct={handleProduct} />)}
                </div>
            </div>
        </div>
    </React.Fragment >
}

export default BillAddPage
