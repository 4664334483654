import React, { useEffect } from "react"
import { Switch, Route, Redirect, useRouteMatch, useHistory, useLocation } from "react-router-dom"
import AuthGuardCanActivate from "../guards/AuthGuardCanActivate"
import AuthLayout from "../layouts/auth/AuthLayout"
import ForgotPasswordPage from "../pages/forgot-password/ForgotPasswordPage"
import ResetPasswordPage from "../pages/reset-password/ResetPasswordPage"
import SignInPage from "../pages/sign-in/SignInPage"
import { USER_AUTH_ROLE } from "../types/providers/type-user-auth"
import NotFound404Page from "../pages/not-found-404/NotFound404Page"

const AuthRouter = () => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const queryParams = new URLSearchParams(useLocation().search)

  useEffect(() => {
    if (!queryParams.get('role') || !Object.values(USER_AUTH_ROLE).includes(queryParams.get('role') as USER_AUTH_ROLE)) {
      history.goBack()
    }
  })

  return <React.Fragment>
    <AuthGuardCanActivate >
      <AuthLayout role={queryParams.get('role') as string}>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/sign-in`} />
          <Route path={`${path}/forgot-password`}>
            <ForgotPasswordPage role={queryParams.get('role') as string} />
          </Route>
          <Route path={`${path}/reset-password`}>
            <ResetPasswordPage />
          </Route>
          <Route path={`${path}/sign-in`}>
            <SignInPage role={queryParams.get('role') as string} />
          </Route>
          <Route path={'*'}>
            <NotFound404Page />
          </Route>
        </Switch>
      </AuthLayout>
    </AuthGuardCanActivate>
  </React.Fragment>
}

export default AuthRouter
