import React, { useEffect } from "react"
import Style from "./Toast.module.scss"
import { helperOpenModal } from "../../helpers/helper-location"
import { useHistory, useLocation } from "react-router-dom"
import { TYPE_MODAL } from "../../types/type-app-modal"
import { TYPE_PAGE } from "../../types/type-app-page"
import { useDispatch } from "react-redux"
import { ReducerAppToastServiceActions } from "../../reducers/reducer-app-toast.service"
import IconPriorityhigh from "../../../assets/icons/icon_priority_high.svg"
import IconCheck from "../../../assets/icons/icon_check.svg"
import IconClose from "../../../assets/icons/icon_close.svg"
import IconInfo from "../../../assets/icons/icon_info.svg"

export enum APP_TOAST_TYPE {
    Default = "default",
    Success = "success",
    Error = "error",
    Info = "info"
}

export interface TypeToastOptions {
    type: APP_TOAST_TYPE,
    message: string,
    action: string,
    duration?: number,
    position?: 'top' | 'bottom'
}

interface Props {
    toastOptions: TypeToastOptions
}

const Toast = (props: Props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const [timeoutInstance, setTimeoutInstance] = React.useState<NodeJS.Timeout>()

    const handleAction = (action: string) => {
        if (action.startsWith('modal-'))
            helperOpenModal(history, location, action as TYPE_MODAL, {})
        else if (action.startsWith('/'))
            history.replace(action as TYPE_PAGE)
        dispatch(ReducerAppToastServiceActions.hideToast())
    }

    useEffect(() => {
        if (props.toastOptions) {
            if (timeoutInstance) clearTimeout(timeoutInstance)
            setTimeoutInstance(setTimeout(() => handleAction(props.toastOptions.action || ''), props.toastOptions.duration || 3000))
        }
    }, [props.toastOptions])

    return <React.Fragment>
        {props.toastOptions && <div className={`${Style['container']} ${Style[props.toastOptions.type]} ${Style[props.toastOptions.position as string || 'bottom']}`}>
            <h3>{props.toastOptions.message}</h3>
            {props.toastOptions.type === APP_TOAST_TYPE.Success && <img src={IconCheck} alt="IconDone" />}
            {props.toastOptions.type === APP_TOAST_TYPE.Error && <img src={IconPriorityhigh} alt="IconError" />}
            {props.toastOptions.type === APP_TOAST_TYPE.Default && <img src={IconClose} alt="IconDefault" />}
            {props.toastOptions.type === APP_TOAST_TYPE.Info && <img src={IconInfo} alt="IconInfo" />}
        </div>}
    </React.Fragment>
}

export default Toast