import React from 'react'
import Style from './ResetPasswordPage.module.scss'
import Button, { ButtonColor, ButtonType, ButtonWidth } from '../../atoms/button/Button'
import FieldOtp from '../../atoms/field-otp/FieldOtp'
import { useHistory } from 'react-router-dom'
import { FieldValues, RegisterOptions, useForm } from 'react-hook-form'
import FieldPassword from '../../atoms/field-password/FieldPassword'

enum FORM_CONTROL {
    NewPassword = 'NewPassword',
    ConfirmPassword = 'ConfirmPassword',
}

const ResetPasswordPage = () => {
    const form = useForm()
    const history = useHistory()

    const formControlName = (control: FORM_CONTROL) => {
        let option: RegisterOptions = {}
        switch (control) {
            case FORM_CONTROL.NewPassword:
                option = {
                    required: '*New Password Is Required',
                }
                break
            case FORM_CONTROL.ConfirmPassword:
                option = {
                    required: '*Conform Password Is Required'
                }
                break
        }
        return form.register(control, option)
    }

    const getFormControlError = (name: FORM_CONTROL) => {
        return form.formState.errors?.[name]?.message as string
    }

    const fInValid = (fErrors: FieldValues) => {
        console.log('Error =>', fErrors)
    }

    const fValid = (fValues: FieldValues) => {
        console.log('Success =>', fValues)
    }

    const onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        form.handleSubmit(fValid, fInValid)()
    }

    return <React.Fragment>
        <form className={Style['container']} onSubmit={onFormSubmit}>
            <h1 className={Style['title']}>Reset Password</h1>
            <div className={Style['wrapper-box']}>
                <div className={`${Style['wrapper']} ${Style['left-column']}`}>
                    <FieldPassword formControlName={formControlName(FORM_CONTROL.NewPassword)} error={getFormControlError(FORM_CONTROL.NewPassword)} name={FORM_CONTROL.NewPassword} label="New password" />
                    <FieldPassword formControlName={formControlName(FORM_CONTROL.ConfirmPassword)} error={getFormControlError(FORM_CONTROL.ConfirmPassword)} name={FORM_CONTROL.ConfirmPassword} label='Confirm password' toggle={true} shouldErrorIconVisible={true} />
                </div>
                <div className={`${Style['wrapper-top']} ${Style['right-column']}`}>
                    <FieldOtp />
                    <div className={Style['resend-text']}>
                        <p>An OTP has been sent to user*******@gmail.com please fill OTP to reset your password 3 tries left.</p>
                        <Button type={ButtonType.Submit}>Resend : 05 :00</Button>
                    </div>
                </div>
            </div>
            <div className={Style['button-box']}>
                <Button color={ButtonColor.Primary} type={ButtonType.Submit} width={ButtonWidth.Expand} >Continue</Button>
                <Button color={ButtonColor.Danger} width={ButtonWidth.Expand} handleClick={() => history.go(-2)} >Cancel</Button>
            </div>
        </form>
    </React.Fragment>
}

export default ResetPasswordPage