import React from 'react'
import Style from './NotFound404Component.module.scss'
import ImgComponentNotDataFound404 from '../../../assets/images/img_component-not_found_404.svg'
import Backarrow from '../../../assets/icons/icon_backarrow.svg'
import Button from '../../atoms/button/Button'

interface Props {
  msg: string,
  handleAdd: () => void
}

const NotFound404Component = (props: Props) => {
  // history.replace('/client')

  return <React.Fragment>
    <div className={Style['container']}>
      <img src={ImgComponentNotDataFound404} alt='ImgComponentNotDataFound404' />
      <p>Not found 404</p>
      <Button preIcon={Backarrow} handleClick={props.handleAdd}>Back to home</Button>
    </div>
  </React.Fragment>
}

export default NotFound404Component

